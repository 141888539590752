import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IconColourProp } from '@ui-v2/types/props';
import { retrieveThemeValues } from '@ui-v2/utils/retrieveThemeValues';
import { hexToRGBA } from '@ui-v2/utils/styleUtils';

export const loadingAnimation = (diameter: number, colour: string) => css`
  position: relative;
  color: transparent;

  &[aria-disabled='true'],
  &[aria-disabled='true']:hover,
  &[aria-disabled='true']:focus {
    color: transparent;
  }

  &::after {
    position: absolute;
    z-index: 1;
    top: calc(50% - ${diameter / 2}px);
    left: calc(50% - ${diameter / 2}px);
    width: ${diameter}px;
    height: ${diameter}px;
    border: 2px solid transparent;
    border-color: ${hexToRGBA(colour, 0.5)};
    border-radius: 50%;
    border-right-color: ${colour};
    animation: loading-anim 1.5s linear infinite;
    content: '';
  }

  @keyframes loading-anim {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;

export interface LoadingSpinnerProps {
  className?: string;
  colour?: IconColourProp;
  diameter: number;
}

const StyledLoadingSpinner = styled.div<LoadingSpinnerProps>(
  ({ colour = 'icons.default', diameter, theme }) => [
    loadingAnimation(
      diameter,
      retrieveThemeValues(theme.colours, colour) as unknown as string,
    ),
    css`
      min-width: ${diameter}px;
      min-height: ${diameter}px;
    `,
  ],
);

const LoadingSpinner = (props: LoadingSpinnerProps) => {
  return <StyledLoadingSpinner {...props} />;
};

export default LoadingSpinner;
